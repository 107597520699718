<template>
<div class="widget-w100">
    <div v-if="loading ">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
    <!-- <div v-if="false" class="list-widget list-dash-items list-widget-structure">
        <div class="tgju-widget light components" style="padding: 5px">
            <ul style="max-height: 200px;overflow: auto;">
                <li v-for="item in category_list" :key="item.id">
                    <slot v-if="!isInHiddenItem(item.id)">
                        <router-link :to="{ name: 'MarketList', params: { market_id: item.id, child_category_list: category_list },}" class="l-item-btn-color-blue">
                            {{ item.label }} - <i :class="'uil ' + (item.type == 'folder' ? 'uil-folder' : 'uil-link-alt') "></i>
                        </router-link>
                    </slot>
                </li>
            </ul>
        </div>
    </div> -->

    <slot v-if="market_values && market_values.data">
            <slot v-if="market_values.data.length">
                <div class="market-main-item" v-for="market_value in market_values.data" :key="market_value.id">
                    <router-link :class="'market-main-item-row r-'+market_value.dt+' '+(market_value.showHighLow ? market_value.dt+'-item': '')+''"  :to="{ name: 'MarketProfile', params: { name: market_value.item_id },}">
                        <slot v-if="market_value.slug.includes('crypto-')">
                            <div class="market-main-item-col-logo">
                                <img :src="'https://static.tgju.org/images/crypto/icons/'+ market_value.slug.replace('crypto-', '') + '_' + market_value.grade.toLowerCase() + '.png'">
                            </div>
                        </slot>
                        <slot v-else-if="market_value.image">
                            <div class="market-main-item-col-logo">
                                <img :src="'https://platform.tgju.org/files/images/'+ market_value.image">
                            </div>
                        </slot>
                        <slot v-else-if="market_value.image == null && market_value.country">
                            <div class="market-main-item-col-logo col-logo-flag">
                                <img :src="'https://www.tgju.org/images/flags/4x3/'+ market_value.country+'.svg'">
                            </div>
                        </slot>
                        <slot v-else>
                            <div class="market-main-item-col-logo col-logo-flag">
                                <img src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                            </div>
                        </slot>
                        <div class="market-main-item-col item-col-right">
                            <div :class="'market-main-item-title ' +(market_value.p_irr || market_value.p_bid ? ' limit-title' : '')">
                                <h2 v-if="market_value.title">
                                    {{ market_value.title }}
                                </h2>
                                <h2 v-else-if="market_value.title_second">
                                    {{ market_value.title_second }}
                                </h2>
                                <h2 v-else-if="market_value.slug">
                                    {{ market_value.slug }}
                                </h2>
                                <h2 v-else> {{ market_value.key }}</h2>
                            </div>
                            <div class="market-main-item-time">
                                <i class="uil uil-clock"></i>
                                <span>{{ market_value.t }}</span>
                            </div>
                        </div>
                        <div class="market-main-item-col item-col-left">

                            <div :class="'market-main-item-price'+' '+ market_value.dt" v-if="market_value.p_irr">
                                {{ formatPrice(market_value.p) }} <div class="item-price-badge">دلار</div>
                            </div>
                            <div :class="'market-main-item-price'+' '+ market_value.dt" v-else-if="market_value.p_bid">
                                {{ formatPrice(market_value.p) }} <div class="item-price-badge">تقاضا</div>
                            </div>
                            <div class="market-main-item-price" v-else>
                                <span>{{ formatPrice(market_value.p) }}
                                </span>
                                <div v-if="market_value.currency == 'irr'" class="item-price-badge">ریال</div>
                                <div v-if="market_value.currency == 'usd'" class="item-price-badge">دلار</div>
                                <div v-if="market_value.currency == 'eur'" class="item-price-badge">یورو</div>
                                <div v-if="market_value.currency == 'gbp'" class="item-price-badge">پوند</div>
                            </div>

                            <div class="market-main-item-change change-size-irr" v-if="market_value.p_irr">
                                {{ market_value.p_irr }} <div class="item-price-badge">ریال</div>
                            </div>
                            <div class="market-main-item-change change-size-irr" v-else-if="market_value.p_bid">
                                {{ market_value.p_bid }} <div class="item-price-badge">عرضه</div>
                            </div>
                            <div :class="'market-main-item-change'+' '+ market_value.dt"  v-if="!market_value.p_irr && !market_value.p_bid">
                                {{ formatPrice(market_value.d) }} ({{ market_value.dp }} %)
                            </div>

                        </div>
                        <!-- <div class="add-to-watchlist" @click.prevent="openMarketAddToWatchListModal(market_value.id)" v-tooltip="'افزودن به واچ لیست'">
                            <i class="uil uil-file-plus-alt"></i>
                        </div> -->
                    </router-link>
                </div>
            </slot>
            <slot v-else>
                <div class="market-main-empty"> 
                    <div class="empty-data">
                        <i class="uil uil-file"></i>
                        <span>مقداری برای نمایش وجود ندارد</span>
                    </div>
                </div>
            </slot>


        <infinite-loading @infinite="infiniteHandler">
            <div slot="spinner">
                <div class="widget-loading"></div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>


    </slot>
    <slot v-if="stock_values && stock_values.data">

        <div class="market-main-item"  v-for="(stock_value, index) in stock_values.data" :key="index">
            <router-link class="market-main-item-row" :to="{ name: 'StockProfileOverview', params: { type: stock_value.info.market,  name: stock_value.info.symbol},}">
                <slot v-if="stock_value.info.avatar">
                    <div class="market-main-item-col-logo col-logo-flag col-logo-flag-border">
                        <img :src="stock_value.info.avatar">
                    </div>
                </slot>
                <slot v-else>
                    <div class="market-main-item-col-logo col-logo-flag">
                        <img src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                    </div>
                </slot>
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-title">
                        <h2 v-if="stock_value.info.title">
                            {{ stock_value.info.title }}
                        </h2>
                        <h2 v-else-if="stock_value.info.symbol">
                            {{ stock_value.info.symbol }}
                        </h2>
                    </div>
                    <div class="market-main-item-time">
                        <i class="uil uil-clock"></i>
                        <span>{{ stock_value.info.market_fa }}</span>
                    </div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price">
                        {{ roundInt(stock_value.info.last_trade.PDrCotVal) }} <div v-if="intToString(roundInt(stock_value.info.last_trade.PDrCotVal))" class="item-price-badge" v-html="intToString(roundInt(stock_value.info.last_trade.PDrCotVal))"></div>
                    </div>
                    <div :class="'market-main-item-change ' + ( stock_value.info.last_trade.last_change < 0 ? 'low' : '' )  +  ( stock_value.info.last_trade.last_change > 0 ? 'high' : '' )">
                        {{ stock_value.info.last_trade.last_change}} ({{ stock_value.info.last_trade.last_change_percentage }} %)
                    </div>
                </div>
            </router-link>
        </div>

        <!-- <div  class="market-main-item-row"  v-for="(stock_value, index) in stock_values.data" :key="index">
            <div class="market-main-item-col item-col-right">
                <router-link :to="{ name: 'StockProfileOverview', params: { type: stock_value.info.market,  name: stock_value.info.symbol},}">
                    <div class="market-main-item-title">
                        <h2 v-if="stock_value.info.title">
                            {{ stock_value.info.title }}
                        </h2>
                        <h2 v-else-if="stock_value.info.symbol">
                            {{ stock_value.info.symbol }}
                        </h2>
                        <h2 v-else> {{ market_value.key }}</h2>
                    </div>
                </router-link>
                <div class="market-main-item-time">
                    <i class="uil uil-clock"></i>
                    <span>{{ stock_value.info.market_fa }}</span>
                </div>
            </div>
            <div class="market-main-item-col item-col-left">
                <div class="market-main-item-price">
                    {{ dynamicStockFilter(stock_value) }}
                </div>
            </div>
        </div> -->
        <infinite-loading @infinite="stockInfiniteHandler">
            <div slot="spinner">
                <div class="widget-loading"></div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </slot>
    <slot v-else-if="shakhes_overview">
        <div class="market-main-item"  v-for="(stock_value, index) in shakhes_overview" :key="index">
            <router-link class="market-main-item-row" :to="{ name: 'StockProfileOverview', params: { type: 'index',  name: stock_value.slug},}">
                <slot v-if="stock_value.info.avatar">
                    <div class="market-main-item-col-logo col-logo-flag col-logo-flag-border">
                        <img :src="stock_value.info.avatar">
                    </div>
                </slot>
                <slot v-else>
                    <div class="market-main-item-col-logo col-logo-flag">
                        <img src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                    </div>
                </slot>
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-title">
                        <h2 v-if="stock_value.title">
                            {{ stock_value.title }}
                        </h2>
                        <h2 v-else-if="stock_value.symbol">
                            {{ stock_value.symbol }}
                        </h2>
                    </div>
                    <div class="market-main-item-time">
                        <i class="uil uil-clock"></i>
                        <span>{{ stock_value.flow_title }}</span>
                    </div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price">
                        {{ formatPrice(stock_value.value) }}
                    </div>
                    
                    <div :class="'market-main-item-change ' + (stock_value.change.includes('-') ? 'low' : 'high')">
                        {{ result_str_replace(stock_value.change) }} ({{result_str_replace(stock_value.change_percentage)}} %)
                    </div>
                </div>
            </router-link>
            <!-- <router-link class="market-main-item-row" :to="{ name: 'StockProfileOverview', params: { type: 'index',  name: stock_value.slug},}"> -->
            <!-- </router-link> -->
        </div>  
    </slot>
    <!-- <slot v-if="market_values && stock_values && shakhes_overview">
        <div class="market-main-empty"> 
            <div class="empty-data">
                <i class="uil uil-file"></i>
                <span>مقداری برای نمایش وجود ندارد</span>
            </div>
        </div>
    </slot> -->
</div>
</template>

<style lang="scss" scoped>
    .show {
        position: absolute;
        background-color: #f1f1f1;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 2px;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    .high.dir,
    .low.dir {
        max-height: 25px;
        margin: 10px;
    }
</style>

<script>
// این کامپوننت دارای اجزای بازار داینامیک می باشد
import InfiniteLoading from "vue-infinite-loading";
import Centrifuge from "centrifuge";
import moment from "moment-jalaali";
import Vue from "vue";

export default {
    name: "MarketDynamicComponent",
    props: ['data', 'parent_category_list', 'is_home'],
    components: {
        InfiniteLoading,
    },
    data: function () {
        return {
            market_values: [],
            stock_values: [],
            shakhes_overview: [],
            breakline_market_values: [],
            market_id: 0,
            page: 2,
            category_list: [],
            loading: false,
            hidden_item: [],

            // hidden_item: ["stocks-stock", 87656, 28046, "stocks-bond", "stocks-index", 28221, "te-indicators-forecast", "te-indicators", "te-countries-forecast", "te-countries", "economics-indicators", "economics-countries", "stocks-future"]
        };
    },
    mounted() {
        this.$root.$on('update-stock-filter', (data) => {
            this.stock_values = [];
            this.getCategoryList(this.$route.params.market_id);
        });
        this.$root.$on('update-stock-filter-flow', (data) => {
            this.stock_values = [];
            this.getCategoryList(this.$route.params.market_id);
        });
        if (this.$route.params.market_id == 'all') {
            this.getStaticMarket();
        } else {
            this.getCategoryList(this.$route.params.market_id);
        }

        Vue.use(InfiniteLoading, {
            slots: {
                noMore: "محتوا کاملاً بارگیری شدند", // you can pass a string value
            },
        });
    },
    methods: {
        result_str_replace(name){
            return name.replace("-", "");
        },
        getPropByString(obj, propString) {
            if (!propString)
                return obj;

            var prop, props = propString.split('.');

            for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
                prop = props[i];

                var candidate = obj[prop];
                if (candidate !== undefined) {
                obj = candidate;
                } else {
                break;
                }
            }
            return obj[props[i]];
        },
        intToString(value) {
            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        dynamicStockFilter: function(stock_value) {
            var result = this.$store.state.stockFilter.value;
            return this.getPropByString(stock_value, result);
        },
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در صفحه را آپدیت میکند
        socketMarket() {
            var self = this;
            var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

            centrifuge.subscribe("tgju:stream", function (ctx) {
                for (var i in ctx.data) {
                    var item = ctx.data[i].split("|");
                    if (item[0] === 'market') {
                        for (const [index, market_item] of Object.entries(self.market_values.data)) {
                            if (market_item.item_id == item[1]) {
                                self.market_values.data[index].d = item[10];
                                self.market_values.data[index].dp = item[11];
                                self.market_values.data[index].dt = item[12];
                                self.market_values.data[index].h = item[7];
                                self.market_values.data[index].l = item[8];
                                self.market_values.data[index].p = item[6];
                                self.market_values.data[index].t = item[13];
                            }
                        }
                    }
                }
            });
            centrifuge.connect();
        },
        getStaticMarket() {
            this.loading = false;
            this.category_list = this.parent_category_list;
            this.$store.commit('setMarketlayer', 2);
        },
        // این متد با توجه به آرگومان ورود لیست بازار ها و یا دسته بندی ها را برمیگرداند
        getCategoryList(select_opt) {
            this.$helpers
                .makeRequest("GET", "/market/category" + (select_opt == 'all' ? '' : '/' + select_opt))
                .then((api_response) => {
                    var hasFolder = api_response.data.response.detail.items.map(item => {
                        return item.type
                    }).includes('folder')

                    if (hasFolder) {
                        this.loading = false;
                        this.category_list = api_response.data.response.detail.items;
                        this.category_list.unshift({id: select_opt, label: 'نمای کلی', type: 'folder'});
                        this.$store.commit('setMarketLvl3', this.category_list);
                        this.$store.commit('setMarketlayer', 3);
                    } else {
                        this.getMarketValue(select_opt);
                        this.$store.commit('setMarketlayer', 4);
                        this.$root.$emit('hide_static_market', true);
                        // this.socketMarket();
                    }
                });
        },
        // این متد نرخ ها و شاخص های یک دسته بندی را با توجه به آرگومان ورود بر میگرداند
        getMarketValue(id) {
            this.loading = true;
            this.$helpers
                .makeRequest("GET", "/market/markets-by-category/" + id ,{
                    sort: this.$store.state.stockFilter.sort,
                    flow: this.$store.state.stockFilterFlow.flow,
                    tab: this.$route.params.market_type + '-' + this.$store.state.currentTabMarket,
                })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_values = api_response.data.response.detail.indicators;
                        if (!api_response.data.response.detail.indicators) {
                            if (api_response.data.response.shakhes) {
                                this.shakhes_overview = api_response.data.response.detail;
                            } else {
                                this.stock_values = api_response.data.response.detail.instruments;
                            }
                        }
                        this.loading = false;
                    }
                });
        },
        // این متد برای لود بینهایت صفحه بندی به صورت اسکرول برای شاخص ها استفاده میشود
        infiniteHandler($state) {
            this.$helpers
                .makeRequest("GET", "/market/markets-by-category/" + this.$route.params.market_id, {
                    page: this.page,
                    sort: this.$store.state.stockFilter.sort,
                    flow: this.$store.state.stockFilterFlow.flow,
                    tab: this.$route.params.market_type + '-' + this.$store.state.currentTabMarket,
                })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.indicators.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.indicators.data)) {
                                this.market_values.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        // این متد برای لود بینهایت صفحه بندی به صورت اسکرول برای شاخص ها استفاده میشود
        stockInfiniteHandler($state) {
            this.$helpers
                .makeRequest("GET", "/market/markets-by-category/" + this.$route.params.market_id, {
                    page: this.page,
                    sort: this.$store.state.stockFilter.sort,
                    flow: this.$store.state.stockFilterFlow.flow
                })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.instruments.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.instruments.data)) {
                                this.stock_values.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        // این متد برای باز کردن مودال افزودن نماد به واچ لیست و هندل کال بک آن مورد استفاده قرار میگیرد
        openMarketAddToWatchListModal(symbol) {
            let callbackEvent = this.$router.currentRoute.name + '_MarketAddToWatchList_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MarketAddToWatchList.vue',
                config: {
                    title: 'افزودن نماد به واچ لیست',
                    smallModal: true,
                    data: {
                        symbol: symbol
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addMarketToWatchList') {
                    this.$helpers.makeRequest('POST', '/watchlist/add-remove-market-to-watch-list', {
                        watch_list_id: response.data.category_id,
                        market_symbol: symbol
                    }).then(api_response => {
                        Vue.prototype.$toast.success({
                            title: 'موفق',
                            message: api_response.data.response.status == 'add-success' ? 'با موفقیت افزوده شد' : 'با موفقیت حذف شد',
                        });
                    });
                }
            });
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        isInHiddenItem(value) {
            return this.hidden_item.includes(value);
        },
        // این متد برای هندل کردن منوی دراپ دان لیست می باشد
        dropDown(name) {
            var el = document.getElementById("myDropdown-" + name);

            if (el.classList.contains('d-none')) {
                el.classList.remove('d-none');
                el.classList.toggle('show');
                return 0;
            }

            if (el.classList.contains('show')) {
                el.classList.remove('show');
                el.classList.toggle('d-none');
                return 0;
            }
        },
    },
    computed: {
        getCentrifuge () {
            return this.$store.state.globalCentrifugeData;
        }
    },
    watch: {
        getCentrifuge (newCount, oldCount) {
            // وقتی تو نمای کلی افتاد دیگه اجرا نشه
            if (this.$store.state.currentMarketLvl == 3) {
                return;
            }

            if (this.market_values === undefined) {
                return;
            }
            for (var i in newCount) {
                var item = newCount[i].split("|");
                if (item[0] === 'market') {
                    for (const [index, market_item] of Object.entries(this.market_values.data)) {
                        if (market_item.item_id == item[1]) {
                            this.market_values.data[index].p = item[6];
                            this.market_values.data[index].d = item[10];
                            this.market_values.data[index].dt = item[12];
                            this.market_values.data[index].t = item[13];
                            this.market_values.data[index].updated_at = item[14];
                            Vue.set(this.market_values.data[index], 'showHighLow', true)
                            // منطق زیر برای هندل قیمت ریال در ارز دیجیتال است
                            if (!!this.market_values.data[index].name && this.market_values.data[index].name.includes('crypto-')) {
                                for (var j in newCount) {
                                    var itemJ = newCount[j].split("|");
                                    if (itemJ[0] === 'market') {
                                        if ((this.market_values.data[index].name + '-irr') == itemJ[2]) {
                                            this.market_values.data[index].p_irr = itemJ[6];
                                        }
                                    }
                                }
                            }
                            setTimeout( () => {
                                this.market_values.data[index].showHighLow = false;
                            }, 5000);
                        }
                    }
                }
            }
        }
    }
};
</script>
